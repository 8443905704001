import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Nav from './components/Nav';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Galleries from './pages/Galleries';
import Book from './pages/Book';
import Family from './pages/Family';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

function App() {
    return (
        <Router>
            <Nav />
            <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/galleries" element={<Galleries />} />
                    <Route path="/book" element={<Book />} />
                    <Route path="/family" element={<Family />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/terms" element={<Terms />} />
                    {/* Add other routes as necessary */}
                </Routes>
            <Footer />
        </Router>
    );
}

export default App;