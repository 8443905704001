import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css'; // Add global styles if necessary
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot is now the way to initialize the root rendering in React 18

root.render(
    //<React.StrictMode>
        <App />
    //</React.StrictMode>
);