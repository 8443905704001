import React from 'react';

function Book() {
    return (
        <main>
            <div class="container">
                <section>
                    <h1>Book a Session</h1>
                    <p>Schedule your family photography session with Phil Roberts Photography today!</p>
                    
                    {/* Embed the static booking page as an iframe */}
                    <iframe 
                        src="/booking" 
                        width="100%" 
                        height="1100px" 
                        frameBorder="0" 
                        style={{ border: 'none' }} 
                        title="Book a Session"
                    ></iframe>
                </section>
            </div>
        </main>
    );
}

export default Book;