import React from 'react';

function Home() {
    return (
        <main>
            <div class="container" id="content">
                <section>
                    <h2>St. Augustine: Picture-Perfect Family Moments</h2>
                    <img src="/images/photo.jpg" alt="Family Photo" style={{ float: 'right', marginRight: '20px', width: '300px', height: 'auto' }} />
                    <p><strong>Professional Photography Services</strong><br />
                        Specializing in capturing the spirit and beauty of St. Augustine through professional family portraits.
                    </p>
                    <p>
                        Looking for family photos at a non-beach location? We travel and photograph in five counties of
                        Northeast Florida. Be it a house, a park, a business, a boat, or other favorite local spot, we
                        welcome the opportunity to capture your family where you are most comfortable!
                    </p>
                    <p><strong>Customized Photo Sessions</strong><br />
                        Each photo session is tailored to reflect your family's unique dynamics large family, multi-generational
                        family, or just a couple, set against the historic and picturesque backgrounds of St. Augustine and
                        surrounding areas. Let’s create a plan based on your vision: We guide you in regards to attire, timing,
                        pose ideas, creative ideas, and more. We will contact you to discuss it all in-depth with you!
                    </p>
                    <p><strong>Complimentary 11x17 Print</strong><br />
                        All bookings include a free 11x17 print or (2) 8x10 prints, printed in-house to ensure the highest quality.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </section>

                <div className="photo-gallery">
                    <img src="/images/photo1.jpg" alt="Veronica and Kids Flowers" className="landscape" />
                    <img src="/images/photo3.jpg" alt="Daughtery Family with husbands arms around wives" className="landscape" />
                    <img src="/images/photo4.jpg" alt="Romantic picture of Robertsons on beach" className="landscape" />
                    <img src="/images/photo6.jpg" alt="Robertson Kids laying on the beach" className="landscape" />
                    <img src="/images/photo2.jpg" alt="Girl and Dog" className="portrait" />
                    <img src="/images/photo5.jpg" alt="Stone Children" className="portrait" />
                </div>

                <section>
                    <h2>What Our Clients Say</h2>
                    <p className="testimonial">"Phil is so easy to work with. Really great with a rambunctious, easily distracted kiddo. The pictures came out absolutely great! We've done school pics and parties with him. He has a way of capturing the best moments, and the best looking feelings. LOVE working with Phil. - The Manna</p>
                    <p className="testimonial">"Hi all, I highly recommend if anyone plans to get family portraits done, to please reach out to Mr. Phil. He did my children's pictures and absolutely blew me away, he did an amazing job. I highly recommend, very professional." - The Stone Family</p>
                    <p className="testimonial">"This is our first time and you and Veronica are making it a wonderful experience!" - The Daugherty Family</p>
                </section>
            </div>
        </main>
    );
}

export default Home;