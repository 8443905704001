import React from 'react';

function Footer() {
    return (
        <div>
            <div class="container">
                <section>
                    <h2>Book Your Session Today!</h2>
                    <p><strong>Ready to create lasting memories in St. Augustine?</strong> Contact us now to book your family photo session and secure a spot in our schedule.</p>
                    <a class="cta" href="/book">Book Now</a>
                </section>
                <section>
                    <h2>Contact Us</h2>
                    <p><strong>Email:</strong> <a href="mailto:contact@philrobertsphotography.com">contact@philrobertsphotography.com</a><br />
                    <strong>Phone:</strong> (904) 944-7427</p>
                </section>
                <section>
                    <h2>Follow Us on Social Media!</h2>
                    <p><a href="https://facebook.com/philrobertsphotographs">Facebook</a> | <a href="https://www.instagram.com/philrobertsphotos/">Instagram</a></p> 
                </section>
            </div>
            <footer>
                <p>&copy; 2024 <a href="https://philrobertsphotography.com">Phil Roberts Photography LLC</a>. All rights reserved.</p>
                <p>
                    <a href="/privacy">Privacy Policy</a> | <a href="/terms">Terms of Service</a> | 
                    <a href="#" className="termly-display-preferences">Consent Preferences</a>
                </p>
            </footer>
        </div>
    );
}

export default Footer;