import React from 'react';

function Galleries() {
    return (
        <main>
            <div class="container" id="content">
                <section>
                    <h1>Our Galleries</h1>
                    <p>Explore our collection of family photography in St. Augustine and beyond.</p>
                    
                    {/* Embed the iframe for the galleries */}
                    <div style={{ position: 'relative', width: '100%', height: '801px', overflow: 'hidden', marginBottom: '20px' }}>
                        <iframe
                            src="https://galleries.philrobertsphotography.com/client?headless=true"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none' }}
                            frameBorder="0"
                            allowFullScreen
                            title="Galleries"
                        ></iframe>
                    </div>
                </section>
            </div>
        </main>
    );
}

export default Galleries;