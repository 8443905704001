import React, { useEffect } from 'react';

function Terms() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.id = "termly-jssdk";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <section>
            <div name="termly-embed" data-id="027ee408-4748-494f-b40a-0b9a6cbb9faa"></div>
        </section>
    );
}

export default Terms;