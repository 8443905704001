import React from 'react';

function Family() {
    return (
        <main>
            <div class="container">
                <section>
                {/* Embed the static booking page as an iframe */}
                    <iframe 
                        src="/familybook" 
                        width="100%" 
                        height="2200px" 
                        frameBorder="0" 
                        style={{ border: 'none' }} 
                        title="Book a Session"
                    ></iframe>
                </section>
            </div>
        </main>
    );
}

export default Family;